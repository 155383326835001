export default class TerminerProjet {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {
        $(document).ready(function () {
            $(".btnValiderSwalCustom").click($.fn.TerminerProjet.terminerPrestation);


            $.fn.TerminerProjet.setBudget(0);

            if ($('#Wipoz_TypeProjetID').val() === "8") {
                $("#document-facture input[id^=AffErrorMsg]").data("mandatory", false);
                $("#document-facture").hide();
            }

            var duree = $("#Duree");
            if (duree) {
                var tb = duree.data("kendoNumericTextBox");
                if (tb) {
                    tb.bind("change", function (e) {
                        Swal.showLoading();
                        $.fn.TerminerProjet.calculPrestation(e.sender.value());
                        $('#Duree_validationMessage').addClass("hidden");
                    });
                }
            }
            var cb = $("#CheckboxTerminerSAV");
            if (cb) {
                cb.on('click', () => {
                    document.getElementById('errorMsgCheckboxTerminerSAV').innerHTML = "";
                });
            }
        });

        $.fn.TerminerProjet = {
            setBudget: function (value) {
                var jElement = $("#BudgetEstime");
                var culture = jElement.data("culture");
                if (!culture) {
                    culture = 'fr-FR';
                }
                var currency = jElement.data("currency");
                if (!currency) {
                    currency = "EUR";
                }
                jElement.val(new Intl.NumberFormat(culture, { style: 'currency', currency: currency }).format(value))
            },
            getErrors: function (init, UploaderRequired) {
                var error = false;
                for (let i = init; i < UploaderRequired.length; i++) {
                    if ($(UploaderRequired[i]).data("mandatory")) {
                        if (UploaderRequired[i].value === "0") {
                            var index = $(UploaderRequired[i]).data("index");
                            if (document.getElementById('errorMsg' + index) != undefined) {
                                document.getElementById('errorMsg' + index).innerHTML = ResourceTerminer.FichierObligatoire;
                                error = true;
                            }
                        }
                    }
                }
                return error;
            },
            terminerPrestation: function (e) {
                var UploaderRequired = $('input[id^=AffErrorMsg]');
                var error = false;

                if ($('#Wipoz_TypeProjetID').val() === "8") {
                    // Les projets SAVs
                    if ($("#OrigineSAV").text() != "Poseur"
                        && $(this.view).data("checkobligatoire")) {
                        // Une durée est obligatoire pour les SAV d'origine autre que poseur si le projet parent du SAV n'est pas un projet créé dans l'outil de gestion
                        var duration = $("#Duree").val();
                        if (duration === undefined || duration === "") {
                            $('#Duree_validationMessage').removeClass("hidden");
                            error = true;
                        }
                        else if (duration == "0") {
                            //pas de facture à générer
                        }
                    }

                    if (!error && UploaderRequired.length > 0) {
                        var compt = 0;
                        error = $.fn.TerminerProjet.getErrors(compt, UploaderRequired);
                    }

                    if ($(this.view).data("checkobligatoire")) {
                        var IsCheckboxCreateSAV = document.getElementById('CheckboxTerminerSAV').checked;
                        if (!IsCheckboxCreateSAV) {
                            document.getElementById('errorMsgCheckboxTerminerSAV').innerHTML = ResourceTerminer.ValidationTerminerSAVChecboxObligatoire;
                            error = true;
                        }
                    }
                }
                else if (UploaderRequired.length > 0) {
                    error = $.fn.TerminerProjet.getErrors(0, UploaderRequired);
                }

                if (error) {
                    e.preventDefault();
                    return false;
                }

                Swal.fire({
                    title: ResourceTerminer.ChargementDocuments,
                    allowOutsideClick: () => !Swal.isLoading()
                });
                Swal.showLoading();

                $("#form-modal-terminer-projet").submit();
            },

            calculPrestation: function (value) {
                $.ajax({
                    url: UrlActionTerminer.GetMontantFacture,
                    type: "POST",
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    data: JSON.stringify({
                        projectId: $("#Wipoz_ProjetID").val(),
                        savDuration: value
                    }),
                    async:false,
                    success: function (data) {
                        Swal.close();
                        $.fn.TerminerProjet.setBudget(data.montant);
                        if ($('#Wipoz_TypeProjetID').val() === "8") {
                            if (data.montant == 0) {
                                $("#document-facture input[id^=AffErrorMsg]").data("mandatory", false);
                                $("#document-facture").hide();
                            }
                            else if ($("#OrigineSAV").text() !== "Poseur") {
                                $("#document-facture input[id^=AffErrorMsg]").data("mandatory", true);
                                $("#document-facture").show();
                            }
                        }
                    },
                    error: function () {
                        Swal.close();
                        toastr.error("Erreur lors du calcul du montant à facturer");
                    },
                });
            }
        };

        window.successTerminerProjet = function (data) {
            Swal.close();
            $('#ModalCustom').modal('hide');
            if (data.result != 0) {
                Swal.fire(data.status, data.message, "error");
            }
            else {
                // Il n'y a pas de facturation dans le cas d'un projet issu de l'outil de gestion
                if (!data.isFromOutilGestion) {
                    window.location.href = UrlActionTerminer.PageFacturation;
                }
                else {
                    window.location.reload();
                }
            }
        }

        window.failureTerminerProjet = function (data) {
            Swal.close();
            $('#ModalCustom').modal('hide');

            if (data.status === 413) {
                Swal.fire(ResourceTerminer.Error, ResourceTerminer.VolumetrieError, "error");
            }
            else {
                Swal.fire(ResourceTerminer.Error, data.statusText, "error").then((result) => {
                    window.location.reload();
                });
            }

        }
    };
}